.what-we-do {
  padding: 108px 0px 0px;

  .title-small {
    color: #3D7BFA;
    font-family: "Berlin Sans FB Regular";
    font-size: 12px;
    letter-spacing: 3px;
    text-align: center;
    line-height: 13px;
    margin-bottom: 15px;
  }

  .title {
    color: #191A25;
    font-family: "Berlin Sans FB Regular";
    font-size: 50px;
    letter-spacing: 0;
    line-height: 55px;
    text-align: center;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .description {
    color: #191A25;
    font-family: 'Lato';
    font-size: 18px;
    letter-spacing: 0;
    max-width: 880px;
    margin: auto;
    line-height: 27px;
    margin-bottom: 40px;
  }

  .btns {
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    a {
      max-width: 236px;
      margin: 0 7px;

      @media (max-width: 768px) {
        margin: 10px 7px;
      }

      &.btn-trans {
        border: 2px solid #3D7BFA;
        border-radius: 5px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3D7BFA;
        font-family: "Berlin Sans FB Regular";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        transition: all 0.5s;
        cursor: pointer;
        padding: 0 20px;
  
        &:hover {
          background-color: #3d7bfa;
          color: #fff;
        }
      }

      &.wo-siblings {
        width: 50%;
        max-width: 486px;
      }

    }
  }
}
